import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { useHover } from 'react-use'
import { ArrowUpRight } from '@vhl/icons'
import { PAGES_SLUG } from '@/constants/pages'

const Policies = () => {
  const variants = {
    initial: {
      y: 0,
    },
    hover: {
      y: -10,
    },
  }
  const PolicyBox = (
    hovered: boolean,
    borderTop: boolean,
    label: string,
    slug: string
  ) => (
    <div
      className={`flex justify-between items-center py-[27px] lg:py-[50px] group ${borderTop &&
        'border-t-[1px]'} border-b-[1px] cursor-pointer`}
    >
      <Link to={slug}>
        <h2 className="transition duration-300 subheading-5 lg:heading-2 group-hover:text-blue-500">
          {label}
        </h2>
      </Link>
      <motion.span
        initial="initial"
        variants={variants}
        animate={hovered ? 'hover' : 'initial'}
      >
        <ArrowUpRight className="w-8 h-8 transition duration-300 lg:w-16 lg:h-16 stroke-black group-hover:stroke-blue-500" />
      </motion.span>
    </div>
  )
  const [Box1] = useHover(hovered =>
    PolicyBox(
      hovered,
      true,
      'Chính sách biên tập',
      PAGES_SLUG.editorial_process
    )
  )
  const [Box2] = useHover(hovered =>
    PolicyBox(
      hovered,
      false,
      'Chính sách bảo mật & sử dụng',
      PAGES_SLUG.privacy_policy
    )
  )
  const [Box3] = useHover(hovered =>
    PolicyBox(
      hovered,
      false,
      'Chính sách quảng cáo',
      PAGES_SLUG.advertising_policy
    )
  )
  return (
    <div className="flex flex-col px-4 py-8 space-y-8 lg:base-container">
      <h1 className="section-heading">Chính sách</h1>
      <div className="flex flex-col">
        {Box1}
        {Box2}
        {Box3}
      </div>
    </div>
  )
}

export default Policies

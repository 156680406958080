const WhoWeAre = () => {
  return (
    <div className="flex flex-col desktop:flex-row">
      <div className="flex items-center justify-center w-full desktop:w-1/2 px-10 py-[145px] py desktop:px-30 desktop:py-[185px] border-[1px] border-l-0 hover:bg-blue-500 transition duration-150 cursor-pointer hover:text-white">
        <h1 className="font-bold text-center section-heading">
          Chúng tôi <br />
          là ai?
        </h1>
      </div>
      <div className="flex flex-col items-center justify-center w-full desktop:w-1/2 px-[30px] py-[76px] border-[1px] border-l-0 border-r-0 hover:bg-blue-500 transition duration-150 cursor-pointer hover:text-white">
        <p className="heading-6 desktop:heading-4">
          Theo tổ chức Y tế thế giới, thông hiểu sức khỏe (health literacy) được
          định nghĩa là mức độ nhận thức, kỹ năng của các cá nhân để truy cập –
          hiểu – sử dụng các nguồn thông tin hoặc đưa ra các hành động nhằm duy
          trì và tăng cường sức khỏe cá nhân và cộng đồng. Trong bối cảnh đại
          dịch COVID-19 diễn ra toàn cầu, sức khỏe và khái niệm thông hiểu sức
          khoẻ càng được quan tâm nhiều hơn.
        </p>
        <br />
        <p className="heading-6 desktop:heading-4">
          Được tài trợ bởi Bộ Ngoại giao Hoa Kỳ, cùng với sự cố vấn đến từ đội
          ngũ nhân viên Y tế giàu kinh nghiệm và uy tín, Vietnam Health Literacy
          là dự án giáo dục phi lợi nhuận.
        </p>
      </div>
    </div>
  )
}

export default WhoWeAre

import BlurryBall from './BlurryBall'

const Hero = () => {
  return (
    <div className="flex flex-col lg:flex-row py-[82px] lg:py-[200px] justify-center items-center space-y-4 lg:space-y-0 lg:justify-between">
      <h1 className="uppercase heading-3 lg:hero whitespace-nowrap">
        Khoa học
      </h1>
      <BlurryBall color="primary" />
      <h1 className="uppercase heading-3 lg:hero whitespace-nowrap">Tin cậy</h1>
      <BlurryBall color="secondary" />
      <h1 className="uppercase heading-3 lg:hero whitespace-nowrap">
        Thường thức
      </h1>
    </div>
  )
}

export default Hero

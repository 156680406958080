import { Fragment, useEffect, useRef, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion, AnimatePresence, useSpring, useTransform } from 'framer-motion'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { useClickAway, useHoverDirty, useToggle } from 'react-use'
import { Chevron, Close } from '@vhl/icons'
import { Team, MemberWithImage } from '@/types'
import { useTeams, useMembersByTeam } from '@/hooks/useMembers'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { Pagination, Grid } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/grid'

type HeaderProps = {
  teams: Team[]
  currentTab: number
  onTabSelected: (index: number) => void
}

interface TabProps {
  tabIndex: number
  title: string
  currentTab: number
  onTabSelected: (index: number) => void
}

function Tab({ tabIndex, title, currentTab, onTabSelected }: TabProps) {
  const isTabSelected = tabIndex === currentTab
  const ref = useRef<HTMLDivElement>(null)
  const isHovering = useHoverDirty(ref)

  const widthValue = useSpring(0, { stiffness: 300, damping: 30 })
  const width = useTransform(widthValue, value => `${value}%`)

  const tabClass = classNames('col-span-1 cursor-pointer transition', {
    'flex flex-col space-y-2': true,
  })
  const textClass = classNames(
    'heading-5',
    { 'text-blue-500': isTabSelected },
    { 'text-gray-300 hover:text-gray-600': !isTabSelected }
  )
  useEffect(() => {
    if (isHovering || isTabSelected) {
      widthValue.set(100)
    }
    if (!isHovering && !isTabSelected) {
      widthValue.set(0)
    }
  }, [isHovering, isTabSelected])
  const dividerClass = classNames(
    'transition duration-300 transform ease-in-out',
    { 'opacity-0': !isHovering },
    { 'opacity-100': isHovering },
    { 'bg-gray-300 hover:bg-gray-600': !isTabSelected },
    { 'bg-blue-500 opacity-100': isTabSelected }
  )
  return (
    <div className={tabClass} onClick={() => onTabSelected(tabIndex)} ref={ref}>
      <h5 className={textClass}>{title}</h5>
      <motion.hr className={dividerClass} style={{ height: '1.5px', width }} />
    </div>
  )
}

// Header include Title and Tab
function Header({ teams, currentTab, onTabSelected }: HeaderProps) {
  const [isSelectOpen, toggleSelect] = useToggle(false)
  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, () => toggleSelect(false))
  return (
    <div className="flex flex-col space-y-8 tablet:flex-row tablet:flex-wrap tablet:justify-around tablet:space-y-16 desktop:flex-nowrap desktop:space-x-30 desktop:space-y-0">
      <div className="w-full desktop:w-1/3">
        <h1 className="section-heading">Thành viên</h1>
      </div>
      {/* Tablet and desktop */}
      <div className="hidden tablet:block desktop:w-2/3 tablet:w-full">
        <div className="grid grid-cols-3 gap-y-6 gap-x-15">
          {teams.map((team, index) => {
            if (index !== 2)
              return (
                <Tab
                  key={index}
                  tabIndex={index}
                  title={team.name}
                  currentTab={currentTab}
                  onTabSelected={onTabSelected}
                />
              )
            return (
              <Fragment key={index}>
                <div className="col-span-1" />
                <Tab
                  tabIndex={index}
                  title={team.name}
                  currentTab={currentTab}
                  onTabSelected={onTabSelected}
                />
              </Fragment>
            )
          })}
        </div>
      </div>
      {/* Mobile */}
      <div className="relative tablet:hidden" ref={ref}>
        <div className="flex flex-col space-y-2">
          <div
            className="flex justify-between border-b-[1px] pb-2 border-gray-600"
            onClick={toggleSelect}
          >
            <h5 className="text-blue-500 heading-6">
              {teams[currentTab].name}
            </h5>
            <Chevron
              className={`stroke-gray-400 transform transition duration-300 ${isSelectOpen &&
                'rotate-180'}`}
            />
          </div>
        </div>
        <AnimatePresence>
          {isSelectOpen && (
            <motion.div
              className="absolute z-10 flex flex-col p-6 space-y-4 border-[1px] border-blue-500 w-full max-h-[180px] overflow-y-scroll bg-white"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {teams.map((team, index) => (
                <h5
                  key={team.key}
                  onClick={() => {
                    onTabSelected(index)
                    toggleSelect()
                  }}
                  className={`heading-6 ${index === currentTab &&
                    'text-blue-500'}`}
                >
                  {team.name}
                </h5>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

function MembersCard({ members }: { members: MemberWithImage[] }) {
  const { isDesktop, isMobile } = useBreakpoints()
  // desktop: 4, tablet: 3, mobile: 2
  const slidesPerView = isDesktop ? 4 : isMobile ? 2 : 3
  const spaceBetween = isMobile ? 12 : 32

  const [selectedMember, setSelectedMember] = useState<MemberWithImage | null>(
    null
  )

  // Member popup only show on mobile
  function MemberPopup() {
    const [isBrowser, setIsBrowser] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    // useClickAway(ref, () => setSelectedMember(null))
    useEffect(() => {
      setIsBrowser(true)
    }, [])
    if (!isBrowser) {
      return null
    }
    return createPortal(
      <>
        <AnimatePresence>
          {selectedMember && (
            <motion.div
              key="member-popup"
              className="fixed inset-0 z-[100]"
              initial={{ backgroundColor: 'rgba(18, 18, 18, 0)' }}
              animate={{ backgroundColor: 'rgba(18, 18, 18, 0.75)' }}
              exit={{ backgroundColor: 'rgba(18, 18, 18, 0)' }}
              transition={{ duration: 0.4 }}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <div
                  ref={ref}
                  className="relative p-2"
                  style={{
                    background: `linear-gradient(180deg, rgba(50, 99, 252, 0) 0%, #3263FC 100%), url(.${selectedMember?.imageFile.images.fallback.src})`,
                    height: selectedMember?.imageFile.height,
                    width: selectedMember?.imageFile.width,
                  }}
                >
                  <div className="flex flex-col justify-end h-full p-2">
                    <h5 className="p-[2px] bg-blue-500 date w-fit text-white">
                      {selectedMember.role}
                    </h5>
                    <h5 className="mb-2 subheading-6 text-blue-25">
                      {selectedMember.name}
                    </h5>
                    <div
                      className="text-white caption"
                      dangerouslySetInnerHTML={{
                        __html: selectedMember.description,
                      }}
                    />
                  </div>
                  <Close
                    className="absolute top-1 right-1 stroke-blue-500"
                    width={28}
                    height={28}
                    onClick={() => setSelectedMember(null)}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>,
      document.body
    )
  }
  return (
    <motion.div
      initial={{ x: '-120%' }}
      animate={{ x: 0 }}
      exit={{ x: '-120%' }}
      transition={{ type: 'spring', stiffness: 90, damping: 15 }}
    >
      <Swiper
        slidesPerView={slidesPerView}
        grid={{
          fill: 'row',
          rows: isMobile ? 3 : 2,
        }}
        pagination={true}
        modules={[Pagination, Grid]}
        style={{ paddingBottom: 96 }}
        spaceBetween={spaceBetween}
      >
        {members.map((member, index) => (
          <SwiperSlide
            key={index}
            style={{ height: 'auto' }}
            onClick={() => {
              // prevent click if it's not mobile
              if (!isMobile) return null
              // prevent click event when popup is open
              if (!selectedMember) setSelectedMember(member)
            }}
          >
            {/* Tablet and desktop */}
            <div className="flex-col hidden h-full p-2 space-y-4 transition duration-200 tablet:flex group hover:bg-blue-500">
              <GatsbyImage image={member.imageFile} alt={member.name} />
              <div className="group-hover:text-white">
                <h5 className="subheading-6">{member.role}</h5>
                <h5 className="subheading-5">{member.name}</h5>
              </div>
              <div
                className="caption group-hover:text-white"
                dangerouslySetInnerHTML={{ __html: member.description }}
              />
            </div>
            {/* Mobile */}
            <div className="relative flex flex-col tablet:hidden">
              <div
                className="absolute z-[2] w-full h-full"
                style={{
                  background:
                    'linear-gradient(180deg, rgba(50, 99, 252, 0) 0%, #3263FC 100%)',
                }}
              />

              <GatsbyImage image={member.imageFile} alt={member.name} />
              <div className="absolute bottom-0 z-10 p-2 text-white">
                <h5 className="p-[2px] bg-blue-500 date w-fit">
                  {member.role}
                </h5>
                <h5 className="subheading-6 text-blue-25">{member.name}</h5>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <MemberPopup />
    </motion.div>
  )
}

const Members = () => {
  const teams = useTeams()
  const [tab, setTab] = useState(0)
  const currentTeam = teams[tab].key
  const { members, team } = useMembersByTeam(currentTeam)
  const { isMobile } = useBreakpoints()
  const containerClass = classNames(
    'flex flex-col overflow-x-hidden',
    { 'pt-8 px-4 space-y-8': isMobile },
    { 'base-container': !isMobile }
  )
  return (
    <div className={containerClass}>
      <Header teams={teams} currentTab={tab} onTabSelected={setTab} />
      <AnimatePresence exitBeforeEnter>
        <MembersCard key={team} members={members} />
      </AnimatePresence>
    </div>
  )
}

export default Members

import classNames from 'classnames'

type BlurryBallProps = {
  className?: string
  color: 'primary' | 'secondary'
}

const BlurryBall = ({ className, color }: BlurryBallProps) => {
  const blurryBallClass = classNames(
    'w-[95px] h-[95px] rounded-full',
    { 'bg-blue-500': color === 'primary' },
    { 'bg-purple-500': color === 'secondary' },
    className
  )
  return (
    <div className={blurryBallClass} style={{ filter: 'blur(15px)' }}></div>
  )
}

export default BlurryBall

import { useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useHoverDirty } from 'react-use'
import classNames from 'classnames'

type StepProps = {
  stepIndex: number
  title: string
  description: string
}
function Step({ stepIndex, title, description }: StepProps) {
  const blurryBallClass = classNames(
    'absolute top-[42px] left-[55px] w-[95px] h-[95px] z-10 bg-blue-500 rounded-full',
    { 'bg-blue-500': stepIndex % 2 !== 0 },
    { 'bg-purple-500': stepIndex % 2 === 0 }
  )
  const boxRef = useRef<HTMLDivElement>(null)
  const isHover = useHoverDirty(boxRef)

  return (
    <motion.div
      className="relative flex justify-between space-x-6 lg:space-x-0 lg:flex-col"
      ref={boxRef}
      whileHover="hover"
    >
      {/* use mr-3 to step text = 1 because 01. has smaller font size, so it should be aligned with other steps */}
      <motion.h1
        className={`lg:mb-6 hero ${stepIndex === 1 && 'mr-3 lg:mr-0'}`}
        initial={{
          WebkitTextStroke: '1px black',
          WebkitTextFillColor: 'transparent',
        }}
        variants={{
          hover: {
            WebkitTextStroke: '1.5px black',
            transition: { duration: 0.2 },
          },
        }}
      >
        0{stepIndex}.
      </motion.h1>
      <div className={`flex flex-col mt-2 space-y-2 lg:mt-0`}>
        <h3 className="heading-4 lg:heading-3 lg:mb-4">{title}</h3>
        <p className="subheading-6 lg:heading-6">{description}</p>
      </div>
      <AnimatePresence exitBeforeEnter>
        {isHover && (
          <motion.div
            key="blurry-ball"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={blurryBallClass}
            style={{ filter: 'blur(15px)' }}
          />
        )}
      </AnimatePresence>
    </motion.div>
  )
}

const ProductionStep = () => {
  const mockDescription =
    'All articles are thoroughly researched and reference high-quality studies and information to support the subject matter.'
  const titles = [
    'Idea & Source',
    'Draft Content',
    'Mentor + Edit',
    'Finalise & Release',
  ]
  return (
    <div className="px-4 py-12 lg:py-24 lg:px-12">
      <div className="flex">
        <div className="hidden lg:block lg:w-1/2"></div>
        <div className="w-full lg:w-1/2 pl-[10px] pb-20 pr-5 lg:pr-0">
          <h1 className="section-heading">
            Sản xuất nội dung <br />
            chất lượng
          </h1>
        </div>
      </div>
      <div className="flex flex-col space-y-10 lg:flex-row lg:space-y-0 lg:space-x-14">
        {titles.map((title, index) => (
          <Step
            key={index}
            stepIndex={index + 1}
            title={title}
            description={mockDescription}
          />
        ))}
      </div>
    </div>
  )
}

export default ProductionStep

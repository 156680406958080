import {
  Hero,
  WhoWeAre,
  ProductionStep,
  Members,
  Policies,
} from '@/components/pages/about'
import SEO from '@/components/shared/SEO'

const About = () => {
  return (
    <div>
      <SEO title="Về chúng tôi" />
      <Hero />
      <WhoWeAre />
      {/* <ProductionStep /> */}
      <hr />
      <Members />
      <hr />
      <Policies />
    </div>
  )
}

export default About
